.form-login {
    width: 330px;
    padding-left: 15px;
}

.form-login input{
   margin-bottom: 10px;
}

.background-login{
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.logologin{
    opacity: 0.5;
    width: 200px;
}

.labinfor{
    font-size: 10px;
}
