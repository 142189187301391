@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.mt-page {
    margin-top: 90px !important;
}

.table {
    font-size: 12px !important;
}



body{
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
}

.react-modal-overlay {
    background: rgb(0, 0, 0,0.5);  
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.react-modal-content {
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 1rem;
    position: relative;
    border-radius: 0.25rem;
    margin-left: 15px;
    margin-right: 15px;
}

.react-modal-close{
    position: absolute;
    right: -20px;
    top: -15px;
    border: 0;
    background: transparent;

}