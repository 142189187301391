.daypago{
    color: green;
    font-style: normal;
}

.dayemdia{
    color: blue;
    font-style: normal;
}

.dayvencido{
    color: red;
    font-style: normal;
}
a.link1 {
    color: rgb(87, 87, 87);
    text-decoration: none;
    cursor: pointer;
}

a.link1 :hover{
    color: rgb(0, 0, 0);
    text-decoration: none;
    cursor: pointer;
}

.card-text-left{
    text-align: left !important;
}

.card {
    padding: 0 0 0 0;
}